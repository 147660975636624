import React from 'react'
import Helmet from 'react-helmet'

const SEO = ({
  title = 'CrossFit X-Factor',
  description = 'CrossFit X-Factor is a premiere strength and conditioning facility located in NW Portland.',
  keywords
}) => {
  keywords = keywords || ''
  return (
    <Helmet>
      <meta charSet='utf-8' />
      <title>{title}</title>
      <meta name='description' content={description} />
      {keywords && keywords.map(keyword => (
        <meta name="keywords" content={keyword} />
      ))}
    </Helmet>
  )
}

export default SEO
